import { DeploymentEnvironment, __PROD__ } from './constants/environment'

const getEnvironmentVariable = (name: string): string => {
  const value = process.env[name]

  if (value === undefined) {
    throw new Error(`Key ${name} is not defined in environment`)
  } else if (value === '') {
    throw new Error(`Key ${name} is defined in environment as an empty string`)
  }
  return value
}

const getDeploymentEnvironment = (): DeploymentEnvironment => {
  return getEnvironmentVariable('REACT_APP_DEPLOYMENT_ENVIRONMENT') as DeploymentEnvironment
}

const getShouldShowAsProduction = (): boolean => {
  const env = getDeploymentEnvironment()
  const showAsProd = 'localStorage' in global && localStorage.getItem('showAsProd')
  return env !== __PROD__ && showAsProd === 'true'
}

/**
 * We use a FeatureToggle component to conditionally render elements based on the environment.
 * This is useful to release new features in Base, hidding them in Demo or showing other components.
 * Thus business can test new features in base without being shown to users in demo.
 * However this has a risk that what they (and us, devs) see is not what the will get once the new feature is released.
 *
 * To avoid that we can pretend to be in Production while in development/base without having to restart the server
 * Set in you browser localStorage.showAsProd = 'true' and reload.
 */
const getOverridableDeploymentEnvironment = (): DeploymentEnvironment => {
  const env: DeploymentEnvironment = getDeploymentEnvironment()
  const showAsProduction: boolean = getShouldShowAsProduction()
  if (showAsProduction) {
    // eslint-disable-next-line no-console
    console.log(`Although your environment is ${env} it will conditionally show elements as it would be in production`)
    return __PROD__
  }

  return env
}

export const FIND_LAY_BY_URL = getEnvironmentVariable('REACT_APP_FIND_LAY_BY_URL')
export const PILOT_TRACKER_URL = getEnvironmentVariable('REACT_APP_PILOT_TRACKER_URL')
export const PORT_INSIDER_URL = getEnvironmentVariable('REACT_APP_PORT_INSIDER_URL')
export const PRONTO_BACKEND_URL = getEnvironmentVariable('REACT_APP_PRONTO_BACKEND_URL')
export const PRONTO_PRODUCT_URL = getEnvironmentVariable('REACT_APP_PRONTO_PRODUCT_URL')
export const AUTH0_DOMAIN = getEnvironmentVariable('REACT_APP_PRONTO_AUTH0_DOMAIN')
export const AUTH0_CLIENT_ID = getEnvironmentVariable('REACT_APP_PRONTO_AUTH0_SPA_CLIENT_ID')
export const PRONTO_MAPBOX_TOKEN = getEnvironmentVariable('REACT_APP_PRONTO_MAPBOX_TOKEN')
export const PRONTO_MAPBOX_STYLE_ID = getEnvironmentVariable('REACT_APP_PRONTO_MAPBOX_STYLE_ID')
export const PRONTO_PORTFLIX_MAPBOX_STYLE_ID = getEnvironmentVariable('REACT_APP_PRONTO_PORTFLIX_MAPBOX_STYLE_ID')
export const PRONTO_SATELLITE_MAPBOX_STYLE_ID = getEnvironmentVariable('REACT_APP_PRONTO_SATELLITE_MAPBOX_STYLE_ID')
export const AIS_BACKEND_URL = getEnvironmentVariable('REACT_APP_AIS_BACKEND_URL')
export const DEVELOPER_PORTAL_BACKEND_URL = getEnvironmentVariable('REACT_APP_DEVELOPER_PORTAL_BACKEND')
export const LOG_FRONTEND_ERRORS = getEnvironmentVariable('REACT_APP_LOG_FRONTEND_ERRORS') === 'true'
export const OVERRIDABLE_DEPLOYMENT_ENVIRONMENT = getOverridableDeploymentEnvironment()
export const SHOULD_SHOW_AS_PROD = getShouldShowAsProduction()
export const SKIPPER_APP_URL = getEnvironmentVariable('REACT_APP_SKIPPER_APP_URL')
export const SOURCE_VERSION = getEnvironmentVariable('REACT_APP_SOURCE_VERSION')
export const MIXPANEL_ID = getEnvironmentVariable('REACT_APP_MIXPANEL_ID')
export const PRODUCT_NAME = 'Synchronizer'
